// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@tailwind base;
@tailwind components;
@tailwind utilities;
// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #1890ff; // dustBlue.600
@info-color: #1890ff; // dustBlue.600
@success-color: #52c41a;
@processing-color: @primary-color;
@error-color: #f5222d; // dustRed.600
@highlight-color: @error-color;
@warning-color: #faad14;
@normal-color: #d9d9d9; // neutral.400
@white-color: #ffffff; // white
@black-color: #000000; // black

.ant-input-prefix, .ant-input-suffix {
  .anticon {
    color: #1890ff;
  }
}

.ant-btn.suffix-btn {
  background: #f5f5f5;

  .anticon {
    color: #1890ff;
    vertical-align: .125em;
  }
}

.anticon {
  vertical-align: .0725em;
}

// SEGMENTED
.ant-segmented {
  height: 46px;
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.ant-segmented-item-selected {
  border: 1px solid @primary-color;
}

.ant-segmented-item {
  height: 40px;
@apply content-center;
}

// CARD
.ant-card {
  border-radius: 10px;
}

// TABLE
.ant-table {
@apply overflow-auto;

  .ant-table-thead > tr > th {
  @apply font-semibold;
  }
}

.ant-tag {
  @apply rounded-xl;
}
// TAG
.ant-tag-geekblue {
  @apply text-geekPrimary-600;
}


