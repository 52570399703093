@tailwind base;
@tailwind components;
@tailwind utilities;

@import './lib/styles/buttons';
@import './lib/styles/sub-header';

@layer base {
  body {
    @apply leading-5.5 text-sm;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    @apply bg-neutral-250;
  }

  h1 {
    @apply text-4.2xl leading-11 font-medium;
    @apply text-dustBlue-1000;
  }

  h2 {
    @apply text-3xl font-medium leading-10;
    @apply text-geekPrimary-1000;
  }

  h3 {
    @apply text-2xl font-medium leading-8;
    @apply text-geekPrimary-1000;
  }

  h5 {
    @apply text-base font-normal leading-6;
    @apply text-black;
  }
}
