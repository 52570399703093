@tailwind base;
@tailwind components;
@tailwind utilities;
button {
  display: flex;
  align-items: center;
}

.sub-header-sticky {
  @apply sticky top-0;
  @apply z-50;
}

@layer base {
  body {
    @apply leading-5.5 text-sm;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    @apply bg-neutral-250;
  }
  h1 {
    @apply text-4.2xl leading-11 font-medium;
    @apply text-dustBlue-1000;
  }
  h2 {
    @apply text-3xl font-medium leading-10;
    @apply text-geekPrimary-1000;
  }
  h3 {
    @apply text-2xl font-medium leading-8;
    @apply text-geekPrimary-1000;
  }
  h5 {
    @apply text-base font-normal leading-6;
    @apply text-black;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyIuLi8uLi8uLi9saWJzL3VpL3NyYy9zdHlsZS5zY3NzIiwiLi4vLi4vLi4vbGlicy91aS9zcmMvbGliL3N0eWxlcy9idXR0b25zLnNjc3MiLCIuLi8uLi8uLi9saWJzL3VpL3NyYy9saWIvc3R5bGVzL3N1Yi1oZWFkZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtBQUNBO0FBQ0E7QUNEQTtFQUNFO0VBQ0E7OztBQ0hGO0VBQ0U7RUFDQTs7O0FGS0Y7RUFDRTtJQUNFO0lBQ0E7SUFDQTs7RUFHRjtJQUNFO0lBQ0E7O0VBR0Y7SUFDRTtJQUNBOztFQUdGO0lBQ0U7SUFDQTs7RUFHRjtJQUNFO0lBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAdGFpbHdpbmQgYmFzZTtcbkB0YWlsd2luZCBjb21wb25lbnRzO1xuQHRhaWx3aW5kIHV0aWxpdGllcztcblxuQGltcG9ydCAnLi9saWIvc3R5bGVzL2J1dHRvbnMnO1xuQGltcG9ydCAnLi9saWIvc3R5bGVzL3N1Yi1oZWFkZXInO1xuXG5AbGF5ZXIgYmFzZSB7XG4gIGJvZHkge1xuICAgIEBhcHBseSBsZWFkaW5nLTUuNSB0ZXh0LXNtO1xuICAgIGZvbnQtZmFtaWx5OiBSb2JvdG8sICdIZWx2ZXRpY2EgTmV1ZScsIHNhbnMtc2VyaWY7XG4gICAgQGFwcGx5IGJnLW5ldXRyYWwtMjUwO1xuICB9XG5cbiAgaDEge1xuICAgIEBhcHBseSB0ZXh0LTQuMnhsIGxlYWRpbmctMTEgZm9udC1tZWRpdW07XG4gICAgQGFwcGx5IHRleHQtZHVzdEJsdWUtMTAwMDtcbiAgfVxuXG4gIGgyIHtcbiAgICBAYXBwbHkgdGV4dC0zeGwgZm9udC1tZWRpdW0gbGVhZGluZy0xMDtcbiAgICBAYXBwbHkgdGV4dC1nZWVrUHJpbWFyeS0xMDAwO1xuICB9XG5cbiAgaDMge1xuICAgIEBhcHBseSB0ZXh0LTJ4bCBmb250LW1lZGl1bSBsZWFkaW5nLTg7XG4gICAgQGFwcGx5IHRleHQtZ2Vla1ByaW1hcnktMTAwMDtcbiAgfVxuXG4gIGg1IHtcbiAgICBAYXBwbHkgdGV4dC1iYXNlIGZvbnQtbm9ybWFsIGxlYWRpbmctNjtcbiAgICBAYXBwbHkgdGV4dC1ibGFjaztcbiAgfVxufVxuIiwiLy8gT3ZlcnJpZGUgdGhpcyBwcm9wZXJ0eSBiZWNhdXNlIHRoZXJlIGlzIGEgY29uZmxpY3QgYmV0d2VlbiBuZy16b3JybyBhbmQgdGFpbHdpbmRcbmJ1dHRvbiB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG4iLCIuc3ViLWhlYWRlci1zdGlja3kge1xuICBAYXBwbHkgc3RpY2t5IHRvcC0wO1xuICBAYXBwbHkgei01MDtcbn1cbiJdfQ== */